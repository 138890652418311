import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

const IndexPage = () => (
    <Layout>
        <SEO title="Aguaman | Kit de Inicio" pathname="/kit-de-inicio" />
        <section className="value-prop kit-value-prop">
            <div className="value-prop-content">
                <div className="main-message">
                    <h1>Kit práctico de inicio</h1>
                    <p className="main-subtitle">
                        Si no tienes botellón o el que tienes está deteriorado, el kit es la&nbsp;
                        <strong>solución</strong> para tu hidratación.
                    </p>
                    <p className="main-subtitle">
                        Evita derramar el agua cuando llenas el vaso o jarra de tu casa o negocio
                        (los pequeños de la casa te lo agradecerán).
                    </p>
                    <p className="main-subtitle">
                        <strong>$ 17.99</strong>
                    </p>
                    <div className="value-prop-buttons">
                        <a
                            className="button button-success"
                            href="https://wa.me/584147353821?text=Hola%20aguaman,%20me%20interesa%20adquirir%20el%20Kit.%20Me%20podrías%20dar%20más%20información"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Comprar Kit
                        </a>
                    </div>
                </div>
                <div className="main-photo"></div>
            </div>
        </section>
        <section className="kit-product-benefits">
            <div className="kit-product-benefits-group">
                <div className="kit-product-benefit-1-tagline">
                    <strong>¿Qué incluye?</strong>{' '}
                    <ul>
                        <li>
                            <p>
                                <strong>Botellón PET de 19 litros</strong> totalmente nuevo
                                recargado con agua purificada.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Dispensador eléctrico recargable</strong>, la duración de la
                                carga es de 3 a 5 botellones de agua (Cable cargador incluído).
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Domicilio hasta la puerta</strong>, te lo llevamos hasta la
                                dirección que desees.
                            </p>
                        </li>
                    </ul>
                </div>
                <div className="kit-product-benefit-1-image"></div>
            </div>
        </section>
        <section className="testimonials">
            <h1 style={{ padding: 0, marginBottom: 0 }}>Entrega a domicilio</h1>
            <h1 style={{ marginTop: 0, padding: 0 }}>GRATIS</h1>
            <div className="testimonial-paragraph" style={{ padding: 0 }}>
                <h3>San Cristóbal y San Juan de Colón - Táchira</h3>
            </div>
        </section>
        {/* <Newsletter /> */}
        <section className="cta">
            <p>Adquiere tu Kit prático de Inicio</p>
            <h1>$17.99</h1>
            <a
                className="button button-success"
                href="https://wa.me/584147353821?text=Hola%20aguaman,%20me%20interesa%20adquirir%20el%20Kit.%20Me%20podrías%20dar%20más%20información"
                target="_blank"
                rel="noreferrer"
            >
                Comprar Kit
            </a>
        </section>
    </Layout>
);

export default IndexPage;
